import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

import ButtonTemplate from "../components/buttons/ButtonTemplate"
import { GatsbyImage } from "gatsby-plugin-image"

const Map = ({ data }) => {
  const { map } = data

  return (
    <Layout>
      <SEO title="Map" />
      <div className="grid justify-items-center mt-12 lg:mt-32">
        <div style={{ maxWidth: 1500 }} className=" w-full px-5 ">
          <GatsbyImage
            image={map.childImageSharp.gatsbyImageData}
            className="w-full shadow"
            alt="Map of the Shibogama First Nations Council Communities"
          />
        </div>
      </div>
      <div className="mx-auto text-center my-16">
        <ButtonTemplate
          to="/"
          text="Back to Home Page"
          className="text-white uppercase tracking-wide mt-8"
          index={1}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MapQuery {
    map: file(relativePath: { eq: "Shib-CommMap-b-Jan2021-Flat.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1500
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          quality: 100
        )
      }
    }
  }
`

export default Map
